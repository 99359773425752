import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Image } from "../image"

import { Link } from "gatsby"

import posed from "react-pose"

const Container = styled.div`
  position: relative;
  height: 100vw;
  cursor: pointer;

  .slider-element {
    overflow: hidden;
  }

  @media (min-width: 576px) {
    height: 100vh;
  }

  img {
    height: ${props => (props.isIndex ? "100%" : "50%")} !important;
    width: ${props => (props.isIndex ? "100%" : "auto")} !important;
    left: ${props => (props.isIndex ? "0" : "50%")} !important;
    top: ${props => (props.isIndex ? "0" : "50%")} !important;
    transform: ${props =>
      props.isIndex ? "none" : "translate(-50%, -50%)"} !important;
  }
`

const StyledImg = styled(Image)`
  position: absolute !important;
  z-index: 996;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Timer = styled.div`
  position: absolute;
  z-index: 997;
  bottom: 0;
  right: 0;
  visibility: hidden;

  @media (min-width: 992px) {
    visibility: visible;
  }

  .progress {
    z-index: 997;
    position: absolute;
    bottom: 2.3rem;
    right: 3rem;
    transform: rotate(-90deg);
    stroke: ${props => (props.textColor ? props.textColor : "#EEEDE9")};
  }

  .progress circle {
    stroke-dasharray: 155;
    stroke-dashoffset: 155;
    animation: dash
      ${props => (props.intervalTime ? props.intervalTime * 2 : 0)}s infinite;
  }

  @keyframes dash {
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -155;
    }
  }
`

const Counter = styled.div`
  position: absolute;
  color: ${props => (props.textColor ? props.textColor : "#EEEDE9")};
  top: 38px;
  left: 35px;
  font-size: 16px;
  font-family: "DM Sans Regular";
  font-weight: 100;
  transform: rotate(90deg);

  span {
    display: inline-block;
    width: 10px;
  }
`

const SliderElement = styled.div`
  position: absolute;
  height: 100vw;
  width: 100vw;
  overflow: hidden;

  @media (min-width: 576px) {
    height: 100vh;
  }

  transition: 2s;
  video {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const Information = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 997;
  padding: 1.5rem;
  max-width: 100%;

  @media (min-width: 576px) {
    max-width: 800px;
    padding: 0 0 3.5rem 3.625rem;
  }

  h1 {
    font-weight: 300;
  }

  a {
    color: ${props => (props.textColor ? props.textColor : "#EEEDE9")};
    text-decoration: none;
  }
`

const Title = styled.div`
  color: ${props => (props.textColor ? props.textColor : "#EEEDE9")};
  margin-bottom: 1rem;
`

const ReadMore = styled.div`
  font-weight: 300 !important;
  color: ${props => (props.textColor ? props.textColor : "#EEEDE9")};

  a {
    font-family: "Roboto" !important;
  }
`

let index = 1

let imageRefs

let interval

const Slider = props => {
  let [showTimer, setShowTimer] = useState(false)
  let [indexCounter, setIndexCounter] = useState(index)

  let intervalTime = props.data.slideDuration ? props.data.slideDuration : 5

  useEffect(() => {
    if (props.data.carouselSlides.length === 0) return
    imageRefs = document.querySelectorAll(".slider-element")
    imageRefs[0].classList.remove("hide")
    imageRefs[0].classList.add("show")

    if (props.data.carouselSlides.length <= 1) return
    Interval()

    return () => {
      clearInterval(interval)
    }
  }, [])

  const Interval = () => {
    setTimeout(() => {
      setShowTimer(true)
    }, 10)

    interval = setInterval(() => {
      setIndexCounter(index + 1)

      imageRefs.forEach(item => {
        item.classList.add("hide")
        item.classList.remove("show")
      })

      if (index < props.data.carouselSlides.length - 1) {
        imageRefs[index].classList.remove("hide")
        imageRefs[index].classList.add("show")
        index++
      } else {
        imageRefs[index].classList.remove("hide")
        imageRefs[index].classList.add("show")
        index = 0
      }
    }, intervalTime * 1000)
  }

  const nextSlide = () => {
    if (props.data.carouselSlides.length <= 1) return
    setShowTimer(false)
    clearInterval(interval)

    setIndexCounter(index + 1)

    imageRefs.forEach(item => {
      item.classList.add("hide")
      item.classList.remove("show")
    })

    if (index < props.data.carouselSlides.length - 1) {
      imageRefs[index].classList.remove("hide")
      imageRefs[index].classList.add("show")
      index++
    } else {
      imageRefs[index].classList.remove("hide")
      imageRefs[index].classList.add("show")
      index = 0
    }

    if (props.data.carouselSlides.length <= 1) return
    Interval()
  }

  return (
    <Container onClick={() => nextSlide()} isIndex={props.isIndex}>
      {showTimer ? (
        <Timer intervalTime={intervalTime} textColor={props.textColor}>
          <span className="progress progress--thin">
            <Counter textColor={props.textColor}>
              <span>{indexCounter}</span>/<span>{props.data.carouselSlides.length}</span>
            </Counter>
            <svg height="75" width="75">
              <circle cx="48" cy="48" r="24" strokeWidth="2" fill="none" />
            </svg>
          </span>
        </Timer>
      ) : (
        <Timer intervalTime={intervalTime} textColor={props.textColor}>
          <span className="progress progress--thin">
            <Counter textColor={props.textColor}>
              <span>{indexCounter}</span>/<span>{props.data.carouselSlides.length}</span>
            </Counter>
            <svg height="75" width="75"></svg>
          </span>
        </Timer>
      )}
      {props.data.carouselSlides.length > 0
        ? props.data.carouselSlides.map(item => {
            return item.image[0] ? (
              <SliderElement className="slider-element hide">
                <Information textColor={props.textColor}>
                  <Title textColor={props.textColor}>
                    <h1>
                      {item.ctaLink !== "" ? (
                        <Link to={item.ctaLink}>{item.titleField}</Link>
                      ) : (
                        item.titleField
                      )}
                    </h1>
                  </Title>
                  <ReadMore textColor={props.textColor}>
                    <h3>
                      {item.ctaLink !== "" ? (
                        <Link to={item.ctaLink}>{item.ctaText} ›</Link>
                      ) : (
                        item.ctaText
                      )}
                    </h3>
                  </ReadMore>
                </Information>
                <StyledImg src={item.image[0]} isIndex={props.isIndex} />
              </SliderElement>
            ) : (
              <SliderElement className="slider-element hide">
                <Information textColor={props.textColor}>
                  <Title textColor={props.textColor}>
                    <h1>
                      {item.ctaLink !== "" ? (
                        <Link to={item.ctaLink}>{item.titleField}</Link>
                      ) : (
                        item.titleField
                      )}
                    </h1>
                  </Title>
                  <ReadMore textColor={props.textColor}>
                    <h3>
                      {item.ctaLink !== "" ? (
                        <Link to={item.ctaLink}>{item.ctaText} ›</Link>
                      ) : (
                        item.ctaText
                      )}
                    </h3>
                  </ReadMore>
                </Information>
                {item.video[0] ? (
                  <video autoPlay muted playsInline loop>
                    <source src={item.video[0].url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </SliderElement>
            )
          })
        : null}
    </Container>
  )
}

export default Slider
