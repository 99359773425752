import React, { useEffect, useState } from "react"

import { Link } from "gatsby"

import styled from "styled-components"
import Img from "gatsby-image"

const Container = styled.div`
  cursor: pointer;
`

const StyledImg = styled(Img)`

  &.desktop-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1s;
  
    img {
      height: 100%;
    }
  }
`

const SliderElement = styled.div`

  &.desktop-image-slider-element {
    height: 100vw;
  }

  position: relative;
  overflow: hidden;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const Information = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 997;
  padding: 1.5rem;
  max-width: 70%;

  h1 {
    font-weight: 300;
  }

  a {
    color: #eeede9;
    text-decoration: none;
  }
`

const Title = styled.div`
  color: #eeede9;
  margin-bottom: 0.5rem;
`

const ReadMore = styled.div`
  color: #eeede9;

  a {
    font-family: "Roboto" !important;
  }
`

const Slider = props => {
  useEffect(() => {}, [])

  return (
    <Container>
      {props.data.carouselSlides.map((item, index) => {
        return item.image[0] || item.mobileimage[0] ? (
          <SliderElement className={!item.mobileimage[0] ? "desktop-image-slider-element" : ""}>
            <Information textColor={props.textColor}>
              <Title textColor={props.textColor}>
                <h1>
                  {item.ctaLink !== "" ? (
                    <Link to={item.ctaLink}>{item.titleField}</Link>
                  ) : (
                    item.titleField
                  )}
                </h1>
              </Title>
              <ReadMore textColor={props.textColor}>
                <h3>
                  {item.ctaLink !== "" ? (
                    <Link to={item.ctaLink}>{item.ctaText} ›</Link>
                  ) : (
                    item.ctaText
                  )}
                </h3>
              </ReadMore>
            </Information>
            {!item.mobileimage[0] ? 
              <StyledImg className="desktop-image" fluid={item.image[0].imageFile.childImageSharp.fluid} />
              :
              <StyledImg className="mobile-image" fluid={item.mobileimage[0].imageFile.childImageSharp.fluid} />
            }
          </SliderElement>
        ) : (
          <SliderElement className="desktop-image-slider-element">
            <Information textColor={props.textColor}>
              <Title textColor={props.textColor}>
                <h1>
                  {item.ctaLink !== "" ? (
                    <Link to={item.ctaLink}>{item.titleField}</Link>
                  ) : (
                    item.titleField
                  )}
                </h1>
              </Title>
              <ReadMore textColor={props.textColor}>
                <h3>
                  {item.ctaLink !== "" ? (
                    <Link to={item.ctaLink}>{item.ctaText} ›</Link>
                  ) : (
                    item.ctaText
                  )}
                </h3>
              </ReadMore>
            </Information>
            {item.video[0] ? (
              <video autoPlay muted playsInline loop>
                <source src={item.video[0].url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </SliderElement>
        )
      })}
    </Container>
  )
}

export default Slider
