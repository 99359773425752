import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Slider from "./slices/slider-mobile"

const MobileVersion = props => {
  return <Slider data={props.data} isIndex={props.isIndex} textColor={""} />
}

export default MobileVersion
