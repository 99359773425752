import React from "react"

import Slider from "./slices/slider"

import Grid from "./news/home-grid"

export default props => {

  // return <Slider data={props.data} isIndex={props.isIndex} textColor={""} />
  return <Grid data={props.data} />
}
