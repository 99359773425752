import React from "react"
import Img from "gatsby-image"

export const Image = ({
  src,
  fixed,
  fluid,
  objectFit = "cover",
  objectPosition = "50% 50%",
  ...props
}) => {
  if (src === undefined) return null
  return src.imageFile ? (
    <Img
      loading="eager"
      {...props}
      fluid={src.imageFile.childImageSharp.fluid}
      fixed={fixed}
    />
  ) : (
    <img
      width="100%"
      height="100%"
      src={src.url}
      loading="eager"
      style={{ objectFit, objectPosition }}
      {...props}
    />
  )
}
